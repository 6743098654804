import React from 'react'

function Home() {
  return (
    <div>
              <main className="wrapper">
        {/* Home Section */}
        <section data-scroll-index={0} className="home-section" id='home'>
          <div className="container">
            <div className="row min-vh-100 align-items-center gy-5">
              <div className="col-lg-7 pe-lg-5">
                <div className="home-intro">
                  <h6>INTRODUCTION</h6>
                  <h2>I'm SAHIL ATTAR <span className="text-theme">MERN STACK DEVLOPER </span></h2>
                  <p>I am a MERN Stack Developer and Project Consultant, and an alumna of SGU. My passion lies in Computer Science, with a focus on software development and decentralized systems. I have a strong interest in blockchain technology, smart contracts, and Web3 development</p>

                  <div className="hs-exp">
                    <div className="exp-box">
                      <h5>4+</h5>
                      <span>YEARS<br /> OF EXPERIENCE</span>
                    </div>
                    <div className="exp-box">
                      <h5>10+</h5>
                      <span>PROJECTS<br /> COMPLETED</span>
                    </div>
                  </div>
                  <div className="btn-bar">
                    {/* <a className="link-effect" href="https://drive.google.com/file/d/1d6_RCgpYE8lB4_-1YLktZejMVwFNwv0X/view?usp=sharing">Browse Resume <i className="bi bi-arrow-up-right" /></a> */}
                  </div>
                </div>
              </div>
              <div className="col-lg-5">
                <div className="home-image">
                  <img src="assets/img/1.jpg" title alt="" />
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* End Home Section */}
      </main>
    </div>
  )
}

export default Home