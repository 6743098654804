import React from 'react'

function Contact() {
  return (
    <div>
         <main className="wrapper" >
        {/* Contact Section */}
        <section data-scroll-index={4} className="section contactus-section border-top-g" id='contact'>
          <div className="container">
            <div className="row justify-content-center section-heading">
              <div className="col-lg-6 text-center">
                <h6><span className="text-theme">Contact</span></h6>
                <h3>Get in touch</h3>
                
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-lg-8">
                <div className="contact-form">
                  
                  <form id="contact-form" method="POST">
                    <div className="row gy-4">
                      <div className="col-md-6">
                        <div className="form-group">
                          <input name="Name" id="name" placeholder="Name *" className="form-control" type="text" />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <input name="Email" id="email" placeholder="Email *" className="form-control" type="email" />
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group">
                          <input name="Subject" id="subject" placeholder="Subject *" className="form-control" type="text" />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group">
                          <textarea name="message" id="message" placeholder="Your message *" rows={5} className="form-control" defaultValue={""} />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="send">
                          <button className="link-effect" type="button" value="Send" onclick="send_mail()"> send message <i className="bi bi-arrow-up-right" /></button>
                          <span id="suce_message" className="text-success" style={{display: 'none'}}>Message Sent Successfully</span>
                          <span id="err_message" className="text-danger" style={{display: 'none'}}>Message Sending Failed</span>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="row gy-4 text-center justify-content-center contact-info">
              <div className="col-md-4">
                <div className="contact-name">
                  <div className="icon">
                    <i className="fas fa-map-marker-alt" />
                  </div>
                  <h5>Visit My Office</h5>
                  <p>  Market Yard ,kolhapur <br />Kolhapur City</p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="contact-name">
                  <div className="icon">
                  <i className="fas fa-envelope" />
                   
                  </div>
                  <h5>Mail</h5>
                  <p>sahilattar016@gmail.com</p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="contact-name">
                  <div className="icon">
                  <i className="fas fa-phone-alt" />
                  </div>
                  <h5>Phone</h5>
                  <p>Phone: +917350169128</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* End Contact Section */}
      </main>

    </div>
  )
}

export default Contact